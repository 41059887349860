<template>
  <div class="row justify-content-center">
    <div class="col-md-9">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h3 class="mb-0">Dados da Turma</h3>
          </div>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class=" col-md-6">
              <label class="col-md-12">Nome:*</label>
              <input
                required
                type="text"
                class="form-control"
                v-model="form.nome"
                placeholder="Digite o Nome da turma"
              />
            </div>
         
            <div class="col-md-2">
              <label class="col-md-12">Regra Turma:*</label>
              <input
                required
                type="text"
                class="form-control"
                v-model="form.regra_turma"
                placeholder="Exemplo:6,9"
              />
            </div>
            <div class="col-md-4">
              <label class="col-md-12">Capicidade de atletas:*</label>
              <input
                required
                type="text"
                class="form-control"
                v-model="form.count_atleta"
                placeholder="capacidade de atletas da turma"
              />
            </div>
            </div>
            <div class="form-row">

          <div class="col-md-12">
            <label class="col-md-12">Descrição:*</label>
            <textarea
              required
              type="text"
              class="form-control"
              v-model="form.descricao"
              placeholder="Digite a descrição"
            />
          </div>
          <div class="col-md-12">
            <div v-for="(item, index) in items" :key="index" class="mb-3 row">
              <div class="col-md-4">
                <select
                  v-model="item.diaDaSemana"
                  class="form-control"
                  @change="atualizarItem(index)"
                >
                  <option value="" disabled selected>Selecione o Dia</option>
                  <option value="Domingo">Domingo</option>
                  <option value="Segunda-feira">Segunda-feira</option>
                  <option value="Terça-feira">Terça-feira</option>
                  <option value="Quarta-feira">Quarta-feira</option>
                  <option value="Quinta-feira">Quinta-feira</option>
                  <option value="Sexta-feira">Sexta-feira</option>
                  <option value="Sábado">Sábado</option>
                </select>
              </div>

              <div class="col-md-3">
                <input
                  v-model="item.horaInicio"
                  type="time"
                  class="form-control mr-2"
                  placeholder="Hora Inicial"
                />
              </div>
              <div class="col-md-3">
                <input
                  v-model="item.horaFim"
                  type="time"
                  class="form-control"
                  placeholder="Hora Final"
                />
              </div>
              <div class="col-md-2">
                <button @click="removerItem(index)" class="btn btn-danger ml-3">
                  Remover
                </button>
              </div>
            </div>
            <button @click="adicionarItem()" class="btn btn-primary">
              <i class="fas fa-plus"></i> Adicionar Intervalo de dias e Horários
            </button>
          </div>
          <div class="row form-group col-md-12">
            <div class="col-md-3 pull-left">
              <div class="md-radio-inline">
                <label>Status:*</label>
                <div class="space d-flex">
                  <b-form-radio :inline="true" value="1" v-model="form.status">
                    Ativo
                  </b-form-radio>

                  <b-form-radio :inline="true" value="2" v-model="form.status">
                    Desativado
                  </b-form-radio>

                  <b-form-radio :inline="true" value="3" v-model="form.status">
                    Suspenso
                  </b-form-radio>
                </div>
              </div>
            </div>
          </div>
        </div>

          <div class="form-group row">
            <div class="col-md-12 text-center">
              <button
                @click.prevent="confirm(!lista_campos.id ? 'cria' : 'edita')"
                class="btn btn-primary"
                :disabled="verif"
              >
                Salvar
                <b-spinner
                  v-show="verif"
                  small
                  variant="dark"
                  type="grow"
                  label="Spinning"
                ></b-spinner>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Turmas" }]);
  },
  mixins: [fireAlert],
  data() {
    return {
      form: {
        nome: "",
        descricao: "",
        dias_semanas: null,
        regra_turma: "",
        status: 1,
        count_atleta:null
      },
      verif: false,
      currentPage: 1,
      perPage: 10,
      editarProduto: true,
      items: [],
    };
  },
  created() {
    this.preencherCampos();
  },
  computed: {
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.turma.mensagem_alert;
    },
  },
  watch: {},
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estás seguro?",
        message: `Esta ação  ` + tipo + ` uma Turma no sistema?`,
        button: {
          no: "No",
          yes: "Sim",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.form.dias_semanas = JSON.stringify(this.items)

      this.verif = true;
      await this.$store.dispatch("turma/create_turma", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "turma",
      });
    },
    async update() {
      this.form.dias_semanas = JSON.stringify(this.items)

      this.verif = true;
      await this.$store.dispatch("turma/update_turma", this.form);
      this.verif = false;

      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "turma",
      });
    },
    preencherCampos() {
      if (this.lista_campos) {
        this.form = {
          id: this.lista_campos.id,
          nome: this.lista_campos.nome,
          descricao: this.lista_campos.descricao,
          status: this.lista_campos.status,
          
          regra_turma: this.lista_campos.regra_turma,
          count_atleta: this.lista_campos.count_atleta,

        };
 this.items = JSON.parse(this.lista_campos.dias_semanas)

      }
    },
    adicionarItem() {
      this.items.push({
        diaDaSemana: "",
        horaInicio: "",
        horaFim: "",
      });
    },
    removerItem(index) {
      this.items.splice(index, 1);
    },
    atualizarItem(index) {
      // Realize quaisquer atualizações necessárias quando a seleção do campo de seleção mudar.
      console.log("Dia selecionado:", this.items[index].diaDaSemana);
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgb(167, 183, 255);
}
</style>
